import {useAuth0} from "#imports";
import {RouteLocationNormalized} from "vue-router";
import {buildContinueUrl} from "~/src/support/urlUtils";

export default defineNuxtRouteMiddleware(async (to: RouteLocationNormalized, from) => {
    if (process.client) {
        const user = await useAuth0().getUser();
        const redirectUrl = buildRedirectUrl(to);

        if (!user.value && redirectUrl) {
            const route = useRoute();

            return useAuth0().authorize(redirectUrl, route.query.email ?? '');
        }
    }
})

const buildRedirectUrl = (to: RouteLocationNormalized): string | false => {
    if (to.name.startsWith("checkout-id")) {
        return buildContinueUrl(to.params.id, to.query.token);
    }

    return false;
}